import React from 'react'

import { getOptions } from 'api/options'
import { getEntity, } from 'api/entity'
import {
  getMainBrand,
  getBrandData
} from 'api/brand'
import { normalizeData, } from 'api/utils'

import Layout from 'layouts/default'
import SectionBuilder from '@/organisms/SectionBuilder'

export const getStaticProps = async () => {
  const generalBrand = await getMainBrand()

  const brands = await getEntity({
    entity: 'brands',
    params: { _fields: 'id,type,title,slug,header,footer,acf.favicon,acf.homepage,acf.isVertical,acf.logoHeader,acf.logoFooter,acf.socials' }
  })

  const [
    brandData = {},
    options
  ] = await Promise.all([
    getBrandData(generalBrand, brands),
    getOptions({ _fields: 'notFoundPage,companyLogo,companyMenu,companyText,club,shop,searchForm', })
  ])

  const {
    current,
    others,
  } = brandData

  const {
    headerMenu = null,
    footerMenu = null,
    favicon = null,
    ...brandRest
  } = current

  const { notFoundPage } = options
  const sections = await normalizeData({ acf: { sections: notFoundPage.sections } })

  return {
    props: {
      title: '404 | Pagina non trovata',
      metadata: {
        title: ' 404 | Pagina non trovata',
        favicon,
      },
      options: {
        isHomepage: false,
        brand: { ...brandRest },
        otherBrands: others,
        ...options,
      },
      additionalData: { showBackToTop: false, },
      sections: sections,
      menus: {
        headerMenu,
        footerMenu
      }
    }
  }
}

export default function NotFound({
  sections,
  metadata,
}) {
  return (
    <Layout metadata={metadata}>
      <SectionBuilder data={sections} />
    </Layout>
  )
}
